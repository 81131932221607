.mt10 {
	margin-top: 10px;
}
.ml10 {
	margin-left: 10px;
}
.mt30 {
	margin-top: 30px;
}
.ml30 {
	margin-left: 30px;
}

.center {
	text-align: center;
}

.clear {
	clear: both;
}