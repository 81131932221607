.f11{
	font-size: 11px;
	/*line-height: 11px;*/
}
.f12{
	font-size: 12px;
	/*line-height: 12px;*/
}
.f13{
	font-size: 13px;
	/*line-height: 13px;*/
}
.f14{
	font-size: 14px;
	/*line-height: 14px;*/
}
.f15{
	font-size: 15px;
	/*line-height: 15px;*/
}
.f16{
	font-size: 16px;
	/*line-height: 16px;*/
}
.f17{
	font-size: 17px;
	/*line-height:17px;*/
}
.f22{
	font-size: 22px;
	/*line-height: 22px;*/
}
.f24{
	font-size: 24px;
	/*line-height: 22px;*/
}
.f25{
	font-size: 25px;
	/*line-height: 22px;*/
}

.fr20{
	font-size: 20px;
}

.fr22{
	font-size: 22px;
}
.fr24{
	font-size: 24px;
}
.fr26{
	font-size:26px;

}
.fr28{
	font-size: 28px;
}

.fr30{
	font-size: 30px;
}
.fr32{
	font-size: 32px;
}
.fr34{
	font-size: 34px;
}
.fr36{
	font-size: 36px;
}
.fr40{
	font-size: 40px;
}
.fr50{
	font-size: 50px;

}
.fw500 {
    font-weight: 500;
}

.lh11 {
	line-height: 11px;
}
.lh12 {
	line-height: 12px;
}
.lh13 {
	line-height: 13px;
}
.lh14 {
	line-height: 14px;
}
.lh15 {
	line-height: 15px;
}
.lh16 {
	line-height: 16px;
}
.lh17 {
	line-height: 17px;
}
.lh22 {
	line-height: 22px;
}
.lh24 {
	line-height: 24px;
}
.lh25 {
	line-height: 25px;
}
.lh20 {
	line-height: 20px;
}
.lh22 {
	line-height: 22px;
}
.lh24 {
	line-height: 24px;
}
.lh26 {
	line-height:26px;
}
.lh28 {
	line-height: 28px;
}
.lh30 {
	line-height: 30px;
}
.lh32 {
	line-height: 32px;
}
.lh34 {
	line-height: 34px;
}
.lh36 {
	line-height: 36px;
}
.lh40 {
	line-height: 40px;
}
.lh50 {
	line-height: 50px;
}