.c6{
	color: #666666;
}
.c9{
	color: #999999;
}
.c3{
	color: #333333;
}
.c4d {
    color: #4d4d4d;
}
.cbd {
	color: #bdbdbd;
}
.cc{
	color: #cccccc;
}
.cf{
	color: #ffffff;
}
.c80 {
	color: #808080;
}
